// Images
import logoImage from '@pig-casino/public/images/logo.webp'
import logo2Image from '@pig-casino/public/images/logo2.webp'
import logoEventXmas from '@pig-casino/public/images/event/xmas.png'
import logoEventChinese from '@pig-casino/public/images/event/chinese.png'
import logoEventValentieDay from '@pig-casino/public/images/event/vday.webp'
import placeholderImage from '@pig-casino/public/images/placeholder.png'
import landingBaccarat24hoursImage from '@pig-casino/public/images/landing-baccarat-24hours.webp'
import landingCasinoImage from '@pig-casino/public/images/landing-casino.webp'
import landingDemoImage from '@pig-casino/public/images/landing-demo.webp'
import landingCasinoGuaranteeImage from '@pig-casino/public/images/landing-casino-guarantee.webp'
import landingRouletteLinkImage from '@pig-casino/public/images/landing-roulette-link.webp'
import landingSicboLinkImage from '@pig-casino/public/images/landing-sicbo-link.webp'
import landingBaccaratLinkImage from '@pig-casino/public/images/landing-baccarat-link.webp'
import lobbyPaymentScanImage from '@pig-casino/public/images/lobby-payment-scan.png'
import lobbyPaymentCryptoImage from '@pig-casino/public/images/lobby-payment-crypto.png'
import lobbyPaymentTruewalletImage from '@pig-casino/public/images/lobby-payment-truewallet.png'
import lobbyPaymentBankImage from '@pig-casino/public/images/lobby-payment-bank.png'
import lobbyPartnerPigspinImage from '@pig-casino/public/images/lobby-partner-pigspin.png'
import lobbyPartnerLottoImage from '@pig-casino/public/images/lobby-partner-lotto.png'
import lobbyPartner918KissImage from '@pig-casino/public/images/lobby-partner-918kiss.png'
import lobbyPartnerScrSlotsImage from '@pig-casino/public/images/lobby-partner-scrslots.png'
import lobbyPartnerBetflikImage from '@pig-casino/public/images/lobby-partner-betflik.png'
import lobbyPartnerHungryPussyImage from '@pig-casino/public/images/lobby-partner-hungrypussy.png'
import lobbyPartnerPigspinWhiteImage from '@pig-casino/public/images/lobby-partner-pigspin-white.png'
import lobbyPartnerLottoWhiteImage from '@pig-casino/public/images/lobby-partner-lotto-white.png'
import lobbyPartner918KissWhiteImage from '@pig-casino/public/images/lobby-partner-918kiss-white.png'
import lobbyPartnerBetflikWhiteImage from '@pig-casino/public/images/lobby-partner-betflik-white.png'
import lobbyPartnerHungryPussyWhiteImage from '@pig-casino/public/images/lobby-partner-hungrypussy-white.png'
import lobbyReward1Image from '@pig-casino/public/images/lobby-reward-1.png'
import lobbyReward2Image from '@pig-casino/public/images/lobby-reward-2.png'
import lobbyReward3Image from '@pig-casino/public/images/lobby-reward-3.png'
import lobbyPromotion1Image from '@pig-casino/public/images/lobby-promotion-1.png'
import lobbyPromotion1DesktopImage from '@pig-casino/public/images/lobby-promotion-1-desktop.png'
import lobbyGameCarouselLightningBacaratImage from '@pig-casino/public/images/lobby-gamecarousel-lightning-baccarat.webp'
import lobbyGameCarouselSpeedBacaratImage from '@pig-casino/public/images/lobby-gamecarousel-speed-baccarat.webp'
import lobbyGameCarouselCrazyTimeImage from '@pig-casino/public/images/lobby-gamecarousel-crazytime.webp'
import lobbyGameCarouselGoldenImage from '@pig-casino/public/images/lobby-gamecarousel-golden-baccarat.webp'
import lobbyGameCarouselGonzosImage from '@pig-casino/public/images/lobby-gamecarousel-gonzos.webp'
import lobbyGameCarouselLightingRouletteImage from '@pig-casino/public/images/lobby-gamecarousel-lightning_roulette.webp'
import lobbyGameCarouselRouletteImage from '@pig-casino/public/images/lobby-gamecarousel-roulette.webp'
import lobbyGameCarouselSuperSicboImage from '@pig-casino/public/images/lobby-gamecarousel-super-sicbo.webp'
import lobbyGameProviderHotImage from '@pig-casino/public/images/lobby-game-provider-hot.png'
import lobbyMenuGame from '@pig-casino/public/images/menu/lobby-game.webp'
import lobbyMenuQuest from '@pig-casino/public/images/menu/lobby-quest.webp'
import lobbyMenuChampion from '@pig-casino/public/images/menu/lobby-champion.webp'
import lobbyMenuReward from '@pig-casino/public/images/menu/lobby-reward.webp'
import lobbyMenuGuild from '@pig-casino/public/images/menu/lobby-guild.webp'
import contactUsFacebookImage from '@pig-casino/public/images/contactus-facebook.png'
import contactUsMessengerImage from '@pig-casino/public/images/contactus-messenger.webp'
import contactUsTwitterImage from '@pig-casino/public/images/contactus-twitter.png'
import contactUsLineImage from '@pig-casino/public/images/contactus-line.png'
import contactUsEmailImage from '@pig-casino/public/images/contactus-email.png'
import hamburgerAvatarImage from '@pig-casino/public/images/hamburger-avatar.png'
import footerSecureEncryptionImage from '@pig-casino/public/images/footer-secure-encrytion.png'
import footerCertificationGroupImage from '@pig-casino/public/images/footer-certification-group.png'
import footerCertificationGroupDesktopImage from '@pig-casino/public/images/footer-certification-group-desktop.png'
import Maintenance from '@pig-casino/public/images/maintenance.webp'
import layoutCoinIcon from '@pig-casino/public/images/layout-coin.png'
import baccaratPageCentreImage from '@pig-casino/public/images/baccarat-page-centre.png'
import baccaratPage24hrImage from '@pig-casino/public/images/baccarat-page-24hr.png'
import baccaratPageLightingImage from '@pig-casino/public/images/baccarat-page-lighting.png'
import baccaratPageEntranceImage from '@pig-casino/public/images/baccarat-page-entrance.png'
import roulettePageDirectImage from '@pig-casino/public/images/roulette-page-direct.png'
import roulettePageRegisterImage from '@pig-casino/public/images/roulette-page-register.png'
import roulettePageDemoImage from '@pig-casino/public/images/roulette-page-demo.png'
import roulettePageEntranceImage from '@pig-casino/public/images/roulette-page-entrance.png'
import highlowPageDirectImage from '@pig-casino/public/images/highlow-page-direct.png'
import highlowPageLoginImage from '@pig-casino/public/images/highlow-page-login.png'
import highlowPageBenefitImage from '@pig-casino/public/images/highlow-page-benefit.png'
import promotionHeaderImage from '@pig-casino/public/images/promotion-header.png'
import ChampionModalImage from '@pig-casino/public/images/champion-modal.png'

// Banks
import kbankImage from '@pig-casino/public/images/banks/kbank.png'
import scbImage from '@pig-casino/public/images/banks/scb.png'
import ktbImage from '@pig-casino/public/images/banks/ktb.png'
import bblImage from '@pig-casino/public/images/banks/bbl.png'
import bayImage from '@pig-casino/public/images/banks/bay.png'
import truewalletImage from '@pig-casino/public/images/banks/truewallet.png'
import promptpayImage from '@pig-casino/public/images/banks/promptpay.png'
import ttbImage from '@pig-casino/public/images/banks/ttb.png'
import kkpImage from '@pig-casino/public/images/banks/kkp.png'
import gsbImage from '@pig-casino/public/images/banks/gsb.png'
import lhImage from '@pig-casino/public/images/banks/lh.png'
import uobImage from '@pig-casino/public/images/banks/uob.png'
import btcImage from '@pig-casino/public/images/banks/btc.png'

// Icons
import { ReactComponent as LayoutBalanceIcon } from '@pig-casino/public/images/layout-balance.svg'
import { ReactComponent as LayoutChatIcon } from '@pig-casino/public/images/layout-chat.svg'
import { ReactComponent as HamburgerSignoutIcon } from '@pig-casino/public/images/hamburger-signout.svg'
import { ReactComponent as HamburgerUnverifiedIcon } from '@pig-casino/public/images/hamburger-unverified.svg'
import { ReactComponent as HamburgerVerifiedIcon } from '@pig-casino/public/images/hamburger-verified.svg'
import { ReactComponent as HamburgerMenuHomeIcon } from '@pig-casino/public/images/hamburger-menu-home.svg'
import { ReactComponent as HamburgerMenuWalletIcon } from '@pig-casino/public/images/hamburger-menu-wallet.svg'
import { ReactComponent as HamburgerMenuPromotionsIcon } from '@pig-casino/public/images/hamburger-menu-promotions.svg'
import { ReactComponent as HamburgerMenuAffilliateIcon } from '@pig-casino/public/images/hamburger-menu-affilliate.svg'
import { ReactComponent as HamburgerMenuGamesIcon } from '@pig-casino/public/images/hamburger-menu-games.svg'
import { ReactComponent as HamburgerMenuContactUsIcon } from '@pig-casino/public/images/hamburger-menu-contactus.svg'
import { ReactComponent as HamburgerMenuFaqIcon } from '@pig-casino/public/images/hamburger-menu-faq.svg'
import { ReactComponent as HamburgerMenuSettingIcon } from '@pig-casino/public/images/hamburger-menu-setting.svg'
import { ReactComponent as LobbyGameLiveIcon } from '@pig-casino/public/images/lobby-game-live.svg'
import { ReactComponent as NavigationBarMainIcon } from '@pig-casino/public/images/navigation-bar-main-icon.svg'
import { ReactComponent as NavigationBarPigSpinIcon } from '@pig-casino/public/images/navigation-bar-pigspin-icon.svg'
import { ReactComponent as HamburgerMenuChampionIcon } from '@pig-casino/public/images/hamburger-menu-champion.svg'
import { ReactComponent as HamburgerMenuQuestIcon } from '@pig-casino/public/images/hamburger-menu-quest.svg'
import { ReactComponent as HamburgerMenuRedeemIcon } from '@pig-casino/public/images/hamburger-menu-redeem.svg'

// Videos
import lobbyHeaderVideo from '@pig-casino/public/images/lobby-header.mp4'
import lobbyHeaderDesktopVideo from '@pig-casino/public/images/lobby-header-desktop.mp4'

// Champion Icon
import ChampionSAIcon from '@pig-casino/public/images/dailyquests/champion/champion-sa-icon.png'
import ChampionCQ9Icon from '@pig-casino/public/images/dailyquests/champion/champion-cq9-icon.png'
import ChampionSexyIcon from '@pig-casino/public/images/dailyquests/champion/champion-sexy-icon.png'
import ChampionBGIcon from '@pig-casino/public/images/dailyquests/champion/champion-bg-icon.png'
import ChampionEVOIcon from '@pig-casino/public/images/dailyquests/champion/champion-evolution-icon.png'
import ChampionDreamGamingIcon from '@pig-casino/public/images/dailyquests/champion/champion-dream_gaming-icon.png'

export {
  ChampionSAIcon,
  ChampionCQ9Icon,
  ChampionSexyIcon,
  ChampionBGIcon,
  ChampionEVOIcon,
  ChampionDreamGamingIcon,
  logoImage,
  logo2Image,
  logoEventXmas,
  logoEventChinese,
  logoEventValentieDay,
  placeholderImage,
  landingBaccarat24hoursImage,
  landingCasinoImage,
  landingCasinoGuaranteeImage,
  landingBaccaratLinkImage,
  landingDemoImage,
  landingRouletteLinkImage,
  landingSicboLinkImage,
  lobbyPaymentBankImage,
  lobbyPaymentCryptoImage,
  lobbyPaymentScanImage,
  lobbyPaymentTruewalletImage,
  lobbyPartnerPigspinImage,
  lobbyPartnerLottoImage,
  lobbyPartner918KissImage,
  lobbyPartnerBetflikImage,
  lobbyPartnerScrSlotsImage,
  lobbyPartnerHungryPussyImage,
  lobbyPartnerPigspinWhiteImage,
  lobbyPartnerLottoWhiteImage,
  lobbyPartner918KissWhiteImage,
  lobbyPartnerBetflikWhiteImage,
  lobbyPartnerHungryPussyWhiteImage,
  lobbyReward1Image,
  lobbyReward2Image,
  lobbyReward3Image,
  lobbyPromotion1Image,
  lobbyPromotion1DesktopImage,
  lobbyGameCarouselLightningBacaratImage,
  lobbyGameCarouselCrazyTimeImage,
  lobbyGameCarouselGoldenImage,
  lobbyGameCarouselGonzosImage,
  lobbyGameCarouselLightingRouletteImage,
  lobbyGameCarouselRouletteImage,
  lobbyGameCarouselSuperSicboImage,
  lobbyGameCarouselSpeedBacaratImage,
  lobbyGameProviderHotImage,
  lobbyHeaderVideo,
  lobbyHeaderDesktopVideo,
  lobbyMenuGame,
  lobbyMenuQuest,
  lobbyMenuChampion,
  lobbyMenuReward,
  lobbyMenuGuild,
  layoutCoinIcon,
  baccaratPageCentreImage,
  baccaratPage24hrImage,
  baccaratPageLightingImage,
  baccaratPageEntranceImage,
  roulettePageEntranceImage,
  roulettePageDemoImage,
  roulettePageRegisterImage,
  roulettePageDirectImage,
  highlowPageDirectImage,
  highlowPageLoginImage,
  highlowPageBenefitImage,
  ChampionModalImage,
  Maintenance,
  LobbyGameLiveIcon,
  LayoutBalanceIcon,
  LayoutChatIcon,
  HamburgerSignoutIcon,
  hamburgerAvatarImage,
  HamburgerUnverifiedIcon,
  HamburgerVerifiedIcon,
  HamburgerMenuHomeIcon,
  HamburgerMenuWalletIcon,
  HamburgerMenuPromotionsIcon,
  HamburgerMenuAffilliateIcon,
  HamburgerMenuContactUsIcon,
  HamburgerMenuFaqIcon,
  HamburgerMenuGamesIcon,
  HamburgerMenuSettingIcon,
  HamburgerMenuChampionIcon,
  HamburgerMenuQuestIcon,
  HamburgerMenuRedeemIcon,
  NavigationBarMainIcon,
  NavigationBarPigSpinIcon,
  contactUsFacebookImage,
  contactUsMessengerImage,
  contactUsTwitterImage,
  contactUsLineImage,
  contactUsEmailImage,
  promotionHeaderImage,
  footerSecureEncryptionImage,
  footerCertificationGroupImage,
  footerCertificationGroupDesktopImage,
  kbankImage,
  scbImage,
  ktbImage,
  bayImage,
  bblImage,
  btcImage,
  gsbImage,
  kkpImage,
  lhImage,
  ttbImage,
  uobImage,
  promptpayImage,
  truewalletImage,
}
